import {
  BiSwim,
  BiCategoryAlt,
  BiMessageRoundedDetail,
  BiCommentDetail,
  BiCog,
  BiArchiveIn,
} from "react-icons/bi";

export const links = [
  {
    name: "Kayıt Olanlar",
    path: "/registered",
    color1: "#FF416C",
    color2: "#FF4B2B",
    icon: <BiSwim />,
  },
  {
    name: "Dönemi Bitenler",
    path: "/expired",
    color1: "#2193b0",
    color2: "#6dd5ed",
    icon: <BiArchiveIn />,
  },
  {
    name: "Hizmetler",
    path: "/",
    color1: "#11998e",
    color2: "#38ef7d",
    icon: <BiCategoryAlt />,
  },
  {
    name: "Mesajlar",
    path: "/",
    color1: "#F2994A",
    color2: "#F2C94C",
    icon: <BiMessageRoundedDetail />,
  },
  {
    name: "Yorumlar",
    path: "/",
    color1: "#fc4a1a",
    color2: "#f7b733",
    icon: <BiCommentDetail />,
  },

  {
    name: "Ayarlar",
    path: "/",
    color1: "#373B44",
    color2: "#4286f4",
    icon: <BiCog />,
  },
];

export const expired = [
  {
    adiniz_soyadiniz: "Elif  durşan",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Madi Aldongar",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-06-19 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "Olga Ay",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "DUHA NEFES BALTACİ",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-08-06 00:00:00",
    bitis_tarih: "2022-08-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif Sude Sarsılmaz",
    sinif: "Pazartesi Çarşamba ",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Aybüke Ece Mercan",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-20 00:00:00",
    bitis_tarih: "2022-08-15 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ali Aladağ",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-27 00:00:00",
    bitis_tarih: "2022-07-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mehmet Umut Bozkurt",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yaşar Dora",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-27 00:00:00",
    bitis_tarih: "2022-07-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ayşe ÇARŞAMBAOĞLU",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-04 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Adem lukas Azboy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-04 00:00:00",
    bitis_tarih: "2022-08-03 00:00:00",
  },
  {
    adiniz_soyadiniz: "Berat toksoy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Hilal Serra gürel",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "AYLİN EKEROĞLU",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "CAN EKEROĞLU",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif sultan Azboy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-04 00:00:00",
    bitis_tarih: "2022-08-03 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ela Gürses",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-27 00:00:00",
    bitis_tarih: "2022-07-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ezel Yıldırım",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mirza Yıldırım",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Sema Aydın",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Melis Aslan",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-20 00:00:00",
    bitis_tarih: "2022-08-15 00:00:00",
  },
  {
    adiniz_soyadiniz: "zümra zahide yeşilkılınç",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-25 00:00:00",
    bitis_tarih: "2022-08-17 00:00:00",
  },
  {
    adiniz_soyadiniz: "Beren Naz Yeşilkılınç",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-25 00:00:00",
    bitis_tarih: "2022-08-17 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ahmet eymen arslan",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-20 00:00:00",
    bitis_tarih: "2022-08-15 00:00:00",
  },
  {
    adiniz_soyadiniz: "Eda Bestesu Akzayim",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-25 00:00:00",
    bitis_tarih: "2022-08-17 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ezgü Gürses",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-06-27 00:00:00",
    bitis_tarih: "2022-07-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ömer yalçın",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ömer Aydın",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ömer taze",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Burak Karataş",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Tuğra kaştaş",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-02 00:00:00",
  },
  {
    adiniz_soyadiniz: "Erol Din Hrnjadovic",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-23 00:00:00",
  },
  {
    adiniz_soyadiniz: "Batur Alp Akbay",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-02 00:00:00",
  },
  {
    adiniz_soyadiniz: "AZra Sağlamel",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Beril İNAN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-06-21 00:00:00",
    bitis_tarih: "2022-07-21 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif Yüksel",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-05 00:00:00",
    bitis_tarih: "2022-08-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Anna Pshehalouk",
    sinif: "Salı perşembe  15:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Galip Demirtaş",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-06-21 00:00:00",
    bitis_tarih: "2022-07-21 00:00:00",
  },
  {
    adiniz_soyadiniz: "TALHA AYAZ BALTACİ",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-08-06 00:00:00",
    bitis_tarih: "2022-08-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "Beril İNAN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-26 00:00:00",
    bitis_tarih: "2022-08-18 00:00:00",
  },
  {
    adiniz_soyadiniz: "Perin Kaya",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Havin Yildirim",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-21 00:00:00",
    bitis_tarih: "2022-08-16 00:00:00",
  },
  {
    adiniz_soyadiniz: "Kıvanç Yildirim",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-21 00:00:00",
    bitis_tarih: "2022-08-16 00:00:00",
  },
  {
    adiniz_soyadiniz: "Melisa Tatar",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Arya Elis ALP",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-05 00:00:00",
    bitis_tarih: "2022-08-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Muhammed can Arslan",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-21 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yusuf şahin eye",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-05 00:00:00",
    bitis_tarih: "2022-08-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Gulbanu Bolat",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-02 00:00:00",
  },
  {
    adiniz_soyadiniz: "Botagoz Bolat",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-02 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ela Beren Yolcuoğlu",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ceylan ziyalar",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ceren ziyalar",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Özlem canatan",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "KAMİL ERDEM",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-06-28 00:00:00",
    bitis_tarih: "2022-07-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "Turgay yılmaz",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yunus Özkan",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-07-05 00:00:00",
    bitis_tarih: "2022-08-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Seyit Doğan",
    sinif: "Salı perşembe 16:00",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-08-02 00:00:00",
  },
  {
    adiniz_soyadiniz: "Alper Algan",
    sinif: "Salı Perşembe 16:00",
    baslama_tarih: "2022-07-26 00:00:00",
    bitis_tarih: "2022-08-18 00:00:00",
  },
  {
    adiniz_soyadiniz: "NISA KARABACAK",
    sinif: "Salı Perşembe 15:00",
    baslama_tarih: "2022-07-19 00:00:00",
    bitis_tarih: "2022-08-11 00:00:00",
  },
  {
    adiniz_soyadiniz: "Sona Rahimova",
    sinif: "Salı Perşembe 16:00",
    baslama_tarih: "2022-07-26 00:00:00",
    bitis_tarih: "2022-08-18 00:00:00",
  },
  {
    adiniz_soyadiniz: "RAMAZAN DEğIRMENCI",
    sinif: "Salı Perşembe 16:00",
    baslama_tarih: "2022-07-26 00:00:00",
    bitis_tarih: "2022-08-18 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yusuf kerem oğuz",
    sinif: "Salı Perşembe 15:00",
    baslama_tarih: "2022-06-23 00:00:00",
    bitis_tarih: "2022-07-26 00:00:00",
  },
  {
    adiniz_soyadiniz: "Duru yavuz",
    sinif: "Salı Perşembe 15:00",
    baslama_tarih: "2022-07-21 00:00:00",
    bitis_tarih: "2022-08-16 00:00:00",
  },
  {
    adiniz_soyadiniz: "Emir halil yavuz",
    sinif: "Salı Perşembe 15:00",
    baslama_tarih: "2022-07-21 00:00:00",
    bitis_tarih: "2022-08-16 00:00:00",
  },
  {
    adiniz_soyadiniz: "Gülfem Sena Uysal",
    sinif: "Hızlandırılmış",
    baslama_tarih: "2022-06-30 00:00:00",
    bitis_tarih: "2022-07-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ela sağlamel",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-06-29 00:00:00",
    bitis_tarih: "2022-08-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mustafa Güven Yaman",
    sinif: "Pazartesi Çarşamba 15.00",
    baslama_tarih: "2022-07-04 00:00:00",
    bitis_tarih: "2022-08-03 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ayşe Beril yıldırım",
    sinif: "Pazartesi Çarşamba 15.00",
    baslama_tarih: "2022-07-06 00:00:00",
    bitis_tarih: "2022-08-08 00:00:00",
  },
  {
    adiniz_soyadiniz: "Eda demirtaşlı",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-06 00:00:00",
    bitis_tarih: "2022-08-08 00:00:00",
  },
  {
    adiniz_soyadiniz: "Hasan salam",
    sinif: "Hızlandırılmış 2 hafta ",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-07-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mina ünal",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-06 00:00:00",
    bitis_tarih: "2022-08-08 00:00:00",
  },
  {
    adiniz_soyadiniz: "Melahat",
    sinif: "Burslı",
    baslama_tarih: "2022-06-19 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "GÜLBERIL PIŞKIN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-05 00:00:00",
    bitis_tarih: "2022-08-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Galip Demirtaş",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-07-26 00:00:00",
    bitis_tarih: "2022-08-18 00:00:00",
  },
  {
    adiniz_soyadiniz: "Arda Kahveci",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "KAYRA ŪNAL",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-07-18 00:00:00",
    bitis_tarih: "2022-08-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elisa Ay",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif defne arslan",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "Emrullah Ata durşan",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mert efe ağrıdağ",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-07-02 00:00:00",
    bitis_tarih: "2022-08-07 00:00:00",
  },
  {
    adiniz_soyadiniz: "PERESKOKOVA AGATA",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-07-30 00:00:00",
    bitis_tarih: "2022-08-21 00:00:00",
  },
  {
    adiniz_soyadiniz: "YERMEK SAKIYEV",
    sinif: "Cumartesi Pazar 13.00",
    baslama_tarih: "2022-06-19 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "ZHULDYZ SAKIYEVA",
    sinif: "Cumartesi Pazar 13.00",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "BURCU BILEN",
    sinif: "Cumartesi Pazar 13.00",
    baslama_tarih: "2022-07-03 00:00:00",
    bitis_tarih: "2022-08-13 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yağmur erva fişne",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Miray fişne",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "DUHA NEFES BALTACİ",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "TALHA AYAZ BALTACİ",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "İpek darıcı",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-11 00:00:00",
    bitis_tarih: "2022-07-03 00:00:00",
  },
  {
    adiniz_soyadiniz: "İpek darıcı",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "Kerem Kaya",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "Fevziye Gülse Atakan",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-19 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "ELİF SARE NACAK",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-05-29 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "HASİBE AKGÜN NACAK",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-05-29 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "FATİH NACAK",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-05-29 00:00:00",
    bitis_tarih: "2022-07-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "ayşe sare kertili",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "fatma kertili",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Kübra Demirel",
    sinif: "Pazar 14.00",
    baslama_tarih: "2022-06-26 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Merve gönülalan",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-18 00:00:00",
    bitis_tarih: "2022-07-24 00:00:00",
  },
  {
    adiniz_soyadiniz: "Esra EFETÜRK",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-18 00:00:00",
    bitis_tarih: "2022-07-24 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yasemin Efetürk",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-06-18 00:00:00",
    bitis_tarih: "2022-07-24 00:00:00",
  },
  {
    adiniz_soyadiniz: "Nihat",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mayada Elayan",
    sinif: "Cumartesi Pazar 14.00",
    baslama_tarih: "2022-06-25 00:00:00",
    bitis_tarih: "2022-07-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Muhammed atabey ibiş",
    sinif: "Cumartesi Pazar ",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ahmed Kayıbey İBİŞ",
    sinif: "Cumartesi Pazar ",
    baslama_tarih: "2022-07-23 00:00:00",
    bitis_tarih: "2022-08-14 00:00:00",
  },
  {
    adiniz_soyadiniz: "YERMEK SAKIYEV",
    sinif: "Cumartesi Pazar 13.00",
    baslama_tarih: "2022-07-31 00:00:00",
    bitis_tarih: "2022-08-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Madi Aldongar",
    sinif: "Salı perşembe 15:00 , Cumartesi Pazar 13:00",
    baslama_tarih: "2022-07-31 00:00:00",
    bitis_tarih: "2022-08-13 00:00:00",
  },
  {
    adiniz_soyadiniz: "АLI ABISIV",
    sinif: "Cumartesi pazar 13:00",
    baslama_tarih: "2022-07-30 00:00:00",
    bitis_tarih: "2022-08-21 00:00:00",
  },
  {
    adiniz_soyadiniz: "MEHMET EYMEN SENER",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-07-31 00:00:00",
    bitis_tarih: "2022-08-27 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yaşar Dora",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-01 00:00:00",
    bitis_tarih: "2022-08-24 00:00:00",
  },
  {
    adiniz_soyadiniz: "Yasemin Efetürk",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-07-30 00:00:00",
    bitis_tarih: "2022-08-21 00:00:00",
  },
  {
    adiniz_soyadiniz: "Tuğra kaştaş",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-02 00:00:00",
    bitis_tarih: "2022-08-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "OMUD I NIDHAMALDIN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-02 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mehmet Umut Bozkurt",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-03 00:00:00",
    bitis_tarih: "2022-08-29 00:00:00",
  },
  {
    adiniz_soyadiniz: "Adem lukas Azboy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-08 00:00:00",
    bitis_tarih: "2022-08-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif sultan Azboy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-08 00:00:00",
    bitis_tarih: "2022-08-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "GÖK DENIZ AZRAK",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-08-06 00:00:00",
    bitis_tarih: "2022-08-28 00:00:00",
  },
  {
    adiniz_soyadiniz: "ZEYNEP AZRA YALÇIN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-02 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mustafa Güven Yaman",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-08 00:00:00",
    bitis_tarih: "2022-08-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "Elif Yüksel",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-09 00:00:00",
    bitis_tarih: "2022-09-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "Batur Alp Akbay",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-04 00:00:00",
    bitis_tarih: "2022-08-30 00:00:00",
  },
  {
    adiniz_soyadiniz: "Burak Karataş",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-03 00:00:00",
    bitis_tarih: "2022-08-31 00:00:00",
  },
  {
    adiniz_soyadiniz: "EMINE NEHIR AKILLI",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-08-03 00:00:00",
    bitis_tarih: "2022-08-29 00:00:00",
  },
  {
    adiniz_soyadiniz: "Gülfem Sena Uysal",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-09 00:00:00",
    bitis_tarih: "2022-09-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "GÜLBERIL PIŞKIN",
    sinif: "Salı perşembe 15;00",
    baslama_tarih: "2022-08-09 00:00:00",
    bitis_tarih: "2022-09-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "AMINE AMBER HANEDAN",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-09 00:00:00",
    bitis_tarih: "2022-09-01 00:00:00",
  },
  {
    adiniz_soyadiniz: "DANIIL KONOPLIN",
    sinif: "Cumartesi Pazar 14:00-15:00",
    baslama_tarih: "2022-08-13 00:00:00",
    bitis_tarih: "2022-09-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "FAIKA ÇETIN",
    sinif: "Cumartesi Pazar  14:00",
    baslama_tarih: "2022-08-14 00:00:00",
    bitis_tarih: "2022-09-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "ERSOY ÇETIN",
    sinif: "Cumartesi Pazar 14:00",
    baslama_tarih: "2022-08-14 00:00:00",
    bitis_tarih: "2022-09-10 00:00:00",
  },
  {
    adiniz_soyadiniz: "Berat toksoy",
    sinif: "Pazartesi Çarşamba 15:00",
    baslama_tarih: "2022-08-15 00:00:00",
    bitis_tarih: "2022-08-24 00:00:00",
  },
  {
    adiniz_soyadiniz: "Sema Aydın",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-08-15 00:00:00",
    bitis_tarih: "2022-09-07 00:00:00",
  },
  {
    adiniz_soyadiniz: "Ömer Aydın",
    sinif: "Pazartesi Çarşamba 16:00",
    baslama_tarih: "2022-08-15 00:00:00",
    bitis_tarih: "2022-09-07 00:00:00",
  },
  {
    adiniz_soyadiniz: "KAMİL ERDEM",
    sinif: "Salı perşembe 16:00 ",
    baslama_tarih: "2022-08-11 00:00:00",
    bitis_tarih: "2022-09-06 00:00:00",
  },
  {
    adiniz_soyadiniz: "MERIH ŞAHIN",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-08-13 00:00:00",
    bitis_tarih: "2022-09-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Perin Kaya",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-16 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
  {
    adiniz_soyadiniz: "Mert efe ağrıdağ",
    sinif: "Cumartesi Pazar 13:00",
    baslama_tarih: "2022-08-13 00:00:00",
    bitis_tarih: "2022-09-04 00:00:00",
  },
  {
    adiniz_soyadiniz: "Beril Müderrisoğlu",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-06-28 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
  {
    adiniz_soyadiniz: "AYŞE ZÜMRA SONMEZ",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-02 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
  {
    adiniz_soyadiniz: "HAMZA ALI SONMEZ",
    sinif: "Salı perşembe 15:00",
    baslama_tarih: "2022-08-02 00:00:00",
    bitis_tarih: "2022-08-25 00:00:00",
  },
];
