import React, { useState } from "react";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { registered } from "../data/kayit";
import UserListBox from "./UserListBox";

const RegisteredTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [filter, setFilter] = useState([...registered]);
  const [modalDate, setModalDate] = useState(false);
  const [modalUser, setModalUser] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleStateButtonClick = () => {
    setModalDate(true);
  };

  const handleUserButtonClick = () => {
    setModalUser(true);
  };

  const requestSearch = (searchVal) => {
    const filteredRows = registered.filter((row) => {
      return row.adiniz_soyadiniz
        .toString()
        .toLowerCase()
        .includes(searchVal.toString().toLowerCase());
    });
    if (searchVal.length < 1) {
      setFilter([...registered]);
    } else {
      setFilter(filteredRows);
    }
  };

  return (
    <div>
      <div>
        <input
          type="search"
          id="search"
          className="block mb-4 px-4 py-2 pl-10 w-5/6 mx-auto text-center text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          placeholder="Arama yapmak için yaz..."
          onChange={(e) => requestSearch(e.target.value)}
        />
      </div>
      {/*<div className={'text-center mb-4'}>Toplam kayit sayisi: {expired.length}</div>*/}
      <table className="w-full">
        <thead className="text-sm w-full">
          <tr className="flex items-center justify-center text-center bg-gray-200 mb-1 border-b border-b-solid border-b-gray-400 w-full pl-1">
            <th className="w-1/6 py-4 font-medium text-left">Detay</th>
            <th className="w-1/3 py-4 font-medium">Ad Soyad</th>
            <th className="w-1/3 py-4 font-medium">Durum</th>
            <th className="w-1/4 py-4 font-medium">İşlem</th>
          </tr>
        </thead>
        <tbody
          className="flex flex-col items-center justify-between overflow-y-scroll w-full"
          style={{ height: "50vh" }}
        >
          {filter.map((item) => {
            return (
              <>
                <tr
                  key={item.id}
                  className={`flex items-center w-full ${
                    isOpen && selectedId === item.id
                      ? "mb-1 border-b-0"
                      : "border-b border-b-solid border-b-gray-400"
                  } px-1 py-2`}
                  onMouseUp={() => {
                    setSelectedId(item.id);
                  }}
                >
                  <td className="py-2 pl-2 w-1/6 flex items-center text-gray-700">
                    <button
                      type="button"
                      className="text-2xl transition-all duration-200"
                      onClick={toggle}
                    >
                      {isOpen && selectedId === item.id ? (
                        <AiFillMinusCircle />
                      ) : (
                        <AiFillPlusCircle />
                      )}
                    </button>
                  </td>
                  <td className="py-2 w-1/3 text-sm text-center">
                    {item.adiniz_soyadiniz.toUpperCase()}
                  </td>
                  <td className="py-2 w-1/3 text-sm text-center">
                    {item.durum === "0" ? "Aktif" : "Pasif"}
                  </td>
                  <td className="py-2 w-1/4 text-xs text-right">
                    <button
                      type="button"
                      className="px-4 py-2 bg-green-300 text-gray-700 rounded shadow-lg font-semibold"
                      onClick={() =>
                        alert("Kursiyer durumu pasif olarak değiştirilmiştir.")
                      }
                    >
                      Pasif
                    </button>
                  </td>
                </tr>
                <div
                  className={`${
                    isOpen && selectedId === item.id
                      ? "flex items-center justify-center gap-4 w-full mb-1 border-b border-b-solid border-b-gray-400"
                      : "hidden"
                  } p-4 transition-all duration-500`}
                >
                  <div>
                    <button
                      type="button"
                      className="w-32 p-2 bg-red-500 text-white rounded shadow-lg font-semibold"
                      onClick={handleStateButtonClick}
                    >
                      Dönem Detay
                    </button>
                    {modalDate && (
                      <div className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0">
                        <div
                          className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 bg-[rgba(49,49,49,0.8)]"
                          onClick={() => setModalDate(false)}
                        ></div>
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#f1f1f1] py-4 px-2 rounded w-11/12">
                          <h2 className="text-xl font-semibold text-black text-center mb-4">
                            Yeni Dönem Ekle
                          </h2>
                          <p className="flex items-center justify-center gap-2">
                            <span className="text-xl">
                              <FaUser />
                            </span>
                            <span className="capitalize">
                              {item.adiniz_soyadiniz.toLowerCase()}
                            </span>
                          </p>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Başlama Tarihi:</span>
                            <span className="w-7/12">
                              <input
                                type="date"
                                id="start-date"
                                onChange={() => {}}
                                className="block p-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={item.kayittarihi.slice(0, 10)}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Bitiş Tarihi:</span>
                            <span className="w-7/12">
                              <input
                                type="date"
                                id="end-date"
                                onChange={() => {}}
                                className="block p-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={`${item.kayittarihi.slice(0, 4)}-${(
                                  "0" +
                                  String(
                                    Number(item.kayittarihi.slice(5, 7)) + 1
                                  )
                                ).slice(-2)}-${item.kayittarihi.slice(8, 10)}`}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Sınıfı:</span>
                            <span className="w-7/12">
                              <input
                                type="text"
                                id="class-text"
                                onChange={() => {}}
                                className="block py-2 pl-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Ödeme Durumu:</span>
                            <span className="w-7/12">
                              <input
                                type="text"
                                id="pay-text"
                                onChange={() => {}}
                                className="block py-2 pl-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-center gap-2 mt-4">
                            <button
                              type="submit"
                              className="bg-red-500 text-white py-2 px-4 rounded shadow-md"
                              onClick={() => setModalDate(false)}
                            >
                              Vazgeç
                            </button>
                            <button
                              type="submit"
                              className="bg-blue-500 text-white py-2 px-4 rounded shadow-md"
                              onClick={() => {}}
                            >
                              Kaydet
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <button
                      type="button"
                      className="w-32 p-2 bg-blue-500 text-white rounded shadow-lg font-semibold"
                      onClick={handleUserButtonClick}
                    >
                      Kişi Kartı
                    </button>
                    {modalUser && (
                      <div className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0">
                        <div
                          className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 bg-[rgba(49,49,49,0.8)]"
                          onClick={() => setModalUser(false)}
                        ></div>
                        <div className="absolute top-8 left-1/2 -translate-x-1/2 bg-[#f1f1f1] py-4 px-2 rounded w-11/12">
                          <h2 className="text-xl font-semibold text-black text-center mb-4">
                            Üyelik Bilgileri
                          </h2>
                          <p className="flex items-center justify-center gap-2">
                            <span className="text-xl">
                              <FaUser />
                            </span>
                            <span className="capitalize">
                              {item.adiniz_soyadiniz.toLowerCase()}
                            </span>
                          </p>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Üyelik Tipi:</span>
                            <span className="w-7/12">
                              <UserListBox
                                list={[{ name: "Yetişkin" }, { name: "Çocuk" }]}
                                selectedItem={
                                  item.uyeliktip === "1"
                                    ? "Yetişkin"
                                    : item.uyeliktip === "2"
                                    ? "Çocuk"
                                    : "null"
                                }
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Cinsiyet:</span>
                            <span className="w-7/12">
                              <UserListBox
                                list={[{ name: "Erkek" }, { name: "Kadın" }]}
                                selectedItem={
                                  item.cinsiyet === "0"
                                    ? "Erkek"
                                    : item.cinsiyet === "1"
                                    ? "Kadın"
                                    : "null"
                                }
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Kayıt Tarihi:</span>
                            <span className="w-7/12">
                              <input
                                type="date"
                                id="start-date"
                                onChange={() => {}}
                                className="block py-2 px-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded shadow-md outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={item.kayittarihi.slice(0, 10)}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Ad Soyad:</span>
                            <span className="w-7/12">
                              <input
                                type="text"
                                id="pay-text"
                                onChange={() => {}}
                                className="block py-2 pl-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded shadow-md outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 capitalize"
                                value={item.adiniz_soyadiniz.toLowerCase()}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">TC Kimlik No:</span>
                            <span className="w-7/12">
                              <input
                                type="text"
                                id="pay-text"
                                onChange={() => {}}
                                className="block py-2 pl-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded shadow-md outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={item.tckimlik}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Telefon No:</span>
                            <span className="w-7/12">
                              <input
                                type="text"
                                id="pay-text"
                                onChange={() => {}}
                                className="block py-2 pl-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded shadow-md outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={item.iletisimvasi}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-between mt-4">
                            <span className="text-sm">Doğum Tarihi:</span>
                            <span className="w-7/12">
                              <input
                                type="date"
                                id="start-date"
                                onChange={() => {}}
                                className="block py-2 px-2 w-full mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded shadow-md outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={item.dogumtarihiniz}
                              />
                            </span>
                          </div>
                          <div className="w-full flex items-center justify-center gap-2 mt-4">
                            <button
                              type="submit"
                              className="bg-red-500 text-white py-2 px-4 rounded shadow-md"
                              onClick={() => setModalUser(false)}
                            >
                              Vazgeç
                            </button>
                            <button
                              type="submit"
                              className="bg-green-500 text-white py-2 px-4 rounded shadow-md"
                              onClick={() => {}}
                            >
                              Düzenle
                            </button>
                            <button
                              type="submit"
                              className="bg-blue-500 text-white py-2 px-4 rounded shadow-md"
                              onClick={() => {}}
                            >
                              Kaydet
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RegisteredTable;
