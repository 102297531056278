import React from "react";
import Title from "../components/Title";
import ExpiredTable from "../components/ExpiredTable";
import Navbar from "../components/Navbar";

const Expired = () => {
  return (
    <>
      <Navbar />
      <Title title="Dönemi Bitenler" />
      <ExpiredTable />
    </>
  );
};

export default Expired;
