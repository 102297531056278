import React from "react";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import RegisteredTable from "../components/RegisteredTable";

const Registered = () => {
  return (
    <>
      <Navbar />
      <Title title="Kayıt Olanlar" />
      <RegisteredTable />
    </>
  );
};

export default Registered;
