export const registered = [
  {
    id: "14",
    adiniz_soyadiniz: "Madi Aldongar",
    dogumtarihiniz: "2017-06-14",
    tckimlik: "99291791018",
    iletisimtel: "",
    iletisimvasi: "05441715847",
    iletisimadisoyadi: "Yermek Sakiyev",
    kayittarihi: "2022-04-16 08:52:20",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "15",
    adiniz_soyadiniz: "Elisa Ay",
    dogumtarihiniz: "2016-08-14",
    tckimlik: "18187400032",
    iletisimtel: "",
    iletisimvasi: "05541880804",
    iletisimadisoyadi: "Olga Ay",
    kayittarihi: "2022-04-16 09:16:25",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "16",
    adiniz_soyadiniz: "Olga Ay",
    dogumtarihiniz: "1978-03-23",
    tckimlik: "28271640886",
    iletisimtel: "05541880804",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-04-16 13:55:50",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "17",
    adiniz_soyadiniz: "Barlas Baysal",
    dogumtarihiniz: "2017-01-02",
    tckimlik: "16531454144",
    iletisimtel: "",
    iletisimvasi: "05370310631",
    iletisimadisoyadi: "Elena Baysal",
    kayittarihi: "2022-04-23 07:01:23",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "18",
    adiniz_soyadiniz: "Simay Özbay",
    dogumtarihiniz: "2014-03-05",
    tckimlik: "3004605288",
    iletisimtel: "",
    iletisimvasi: "5078252021",
    iletisimadisoyadi: "Özlem Özbay",
    kayittarihi: "2022-04-26 10:56:18",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "19",
    adiniz_soyadiniz: "Hakan AYDOĞMUŞ",
    dogumtarihiniz: "2017-09-14",
    tckimlik: "14851510274",
    iletisimtel: "",
    iletisimvasi: "05544235009-05055904653",
    iletisimadisoyadi: "Nezaket AYDOĞMUŞ- Mehmet AYDOĞMUŞ",
    kayittarihi: "2022-04-30 16:06:41",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "20",
    adiniz_soyadiniz: "Viktor Stolbovskii",
    dogumtarihiniz: "2016-06-24",
    tckimlik: "99402894904",
    iletisimtel: "",
    iletisimvasi: " 905073039736",
    iletisimadisoyadi: "Irina Sergeenko",
    kayittarihi: "2022-05-02 15:40:26",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "21",
    adiniz_soyadiniz: "Adalet GÖK TEKİN",
    dogumtarihiniz: "1989-04-18",
    tckimlik: "14248794638",
    iletisimtel: "05065775385",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-03 18:35:31",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "22",
    adiniz_soyadiniz: "mete kipel",
    dogumtarihiniz: "2016-11-21",
    tckimlik: "15397494462",
    iletisimtel: "",
    iletisimvasi: "05077068555",
    iletisimadisoyadi: "nihayet kipel",
    kayittarihi: "2022-05-04 13:17:17",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "23",
    adiniz_soyadiniz: "Gurevych Sava",
    dogumtarihiniz: "2015-04-16",
    tckimlik: "No",
    iletisimtel: "",
    iletisimvasi: " 380675227221",
    iletisimadisoyadi: "Gurevych Galina",
    kayittarihi: "2022-05-06 12:02:00",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "24",
    adiniz_soyadiniz: "Denız Bozkurt",
    dogumtarihiniz: "2015-10-15",
    tckimlik: "21139567622",
    iletisimtel: "",
    iletisimvasi: "5534800952",
    iletisimadisoyadi: "Günay Bozkurt",
    kayittarihi: "2022-05-06 12:23:13",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "25",
    adiniz_soyadiniz: "Tamer Bozkurt",
    dogumtarihiniz: "1982-01-27",
    tckimlik: "15964334320",
    iletisimtel: "5462416363",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-08 16:54:17",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "26",
    adiniz_soyadiniz: "Yasemin Efetürk",
    dogumtarihiniz: "1995-01-01",
    tckimlik: "10432658458",
    iletisimtel: "5428414952",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-10 14:25:41",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "28",
    adiniz_soyadiniz: "Zakhar Antipin",
    dogumtarihiniz: "2017-08-01",
    tckimlik: "766339444",
    iletisimtel: "",
    iletisimvasi: "5461027858",
    iletisimadisoyadi: "Tatiana",
    kayittarihi: "2022-05-19 14:37:43",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "29",
    adiniz_soyadiniz: "Gurevych Sava",
    dogumtarihiniz: "2015-04-16",
    tckimlik: "No",
    iletisimtel: "",
    iletisimvasi: " 380675227221",
    iletisimadisoyadi: "Gurevych Galina",
    kayittarihi: "2022-05-22 15:24:51",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "30",
    adiniz_soyadiniz: "Veli Kerim Ak",
    dogumtarihiniz: "2009-11-21",
    tckimlik: "28780046162",
    iletisimtel: "",
    iletisimvasi: "05454939601",
    iletisimadisoyadi: "Tülay AK",
    kayittarihi: "2022-05-24 16:40:07",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "31",
    adiniz_soyadiniz: "Hasan Ali Ak",
    dogumtarihiniz: "2011-09-24",
    tckimlik: "18208400508",
    iletisimtel: "",
    iletisimvasi: "05454939601",
    iletisimadisoyadi: "Tülay AK",
    kayittarihi: "2022-05-24 16:42:18",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "32",
    adiniz_soyadiniz: "Tülay AK",
    dogumtarihiniz: "1978-05-01",
    tckimlik: "12412590666",
    iletisimtel: "05454939601",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-24 16:43:49",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "33",
    adiniz_soyadiniz: "Turhan-Feyzullah Avci",
    dogumtarihiniz: "2015-12-25",
    tckimlik: "21058303776",
    iletisimtel: "",
    iletisimvasi: "99805802674",
    iletisimadisoyadi: "Gülizar",
    kayittarihi: "2022-05-25 08:22:44",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "34",
    adiniz_soyadiniz: "Elif Taş",
    dogumtarihiniz: "1998-10-24",
    tckimlik: "35116283436",
    iletisimtel: "05385582137",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-26 12:00:42",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "35",
    adiniz_soyadiniz: "Oya demir",
    dogumtarihiniz: "2022-05-27",
    tckimlik: "56746355720",
    iletisimtel: "05424918043",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-05-27 03:44:43",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "36",
    adiniz_soyadiniz: "Suden ürün",
    dogumtarihiniz: "2011-10-01",
    tckimlik: "18082404838",
    iletisimtel: "",
    iletisimvasi: "05078508758",
    iletisimadisoyadi: "Gülistan ürün",
    kayittarihi: "2022-05-28 17:47:58",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "37",
    adiniz_soyadiniz: "Übeydullah Bakır",
    dogumtarihiniz: "1991-06-08",
    tckimlik: "44257716208",
    iletisimtel: "5051800421",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-02 08:50:11",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "38",
    adiniz_soyadiniz: "Mert efe ağrıdağ",
    dogumtarihiniz: "2014-07-30",
    tckimlik: "16555453666",
    iletisimtel: "",
    iletisimvasi: "05331436955",
    iletisimadisoyadi: "Şaban ağrıdağ",
    kayittarihi: "2022-06-04 10:03:23",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "39",
    adiniz_soyadiniz: "Miraç ağrıdağ",
    dogumtarihiniz: "2017-02-01",
    tckimlik: "25138167722",
    iletisimtel: "",
    iletisimvasi: "05331436955",
    iletisimadisoyadi: "Şaban ağrıdağ",
    kayittarihi: "2022-06-04 10:05:54",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "40",
    adiniz_soyadiniz: "İpek darıcı",
    dogumtarihiniz: "2015-01-11",
    tckimlik: "53689012970",
    iletisimtel: "",
    iletisimvasi: "05059081612",
    iletisimadisoyadi: "Hatice darıcı",
    kayittarihi: "2022-06-04 17:01:30",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "41",
    adiniz_soyadiniz: "Yiğit güneş",
    dogumtarihiniz: "2016-05-05",
    tckimlik: "25333163758",
    iletisimtel: "",
    iletisimvasi: "5530120031",
    iletisimadisoyadi: "Halime güneş",
    kayittarihi: "2022-06-06 14:25:14",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "42",
    adiniz_soyadiniz: "Hasan Tuğra yılmaz",
    dogumtarihiniz: "2014-08-10",
    tckimlik: "11746614178",
    iletisimtel: "",
    iletisimvasi: "05346106768",
    iletisimadisoyadi: "Emel yılmaz",
    kayittarihi: "2022-06-09 11:01:58",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "44",
    adiniz_soyadiniz: "Elif defne arslan",
    dogumtarihiniz: "2016-12-12",
    tckimlik: "45616003410",
    iletisimtel: "",
    iletisimvasi: "5414120601",
    iletisimadisoyadi: "Emine arslan",
    kayittarihi: "2022-06-10 07:10:08",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "45",
    adiniz_soyadiniz: "Talha kerem AYDENK",
    dogumtarihiniz: "2015-01-26",
    tckimlik: "19918344144",
    iletisimtel: "",
    iletisimvasi: "05393556305",
    iletisimadisoyadi: "Halit AYDENK",
    kayittarihi: "2022-06-10 09:16:49",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "46",
    adiniz_soyadiniz: "HASİBE AKGÜN NACAK",
    dogumtarihiniz: "1991-03-04",
    tckimlik: "18247301804",
    iletisimtel: "05558164574",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-11 07:45:39",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "47",
    adiniz_soyadiniz: "FATİH NACAK",
    dogumtarihiniz: "1990-09-29",
    tckimlik: "14266434998",
    iletisimtel: "05543514929",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-11 07:46:20",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "48",
    adiniz_soyadiniz: "ELİF SARE NACAK",
    dogumtarihiniz: "2016-08-18",
    tckimlik: "24914275880",
    iletisimtel: "",
    iletisimvasi: "05558164574",
    iletisimadisoyadi: "HASİBE AKGÜN NACAK",
    kayittarihi: "2022-06-11 07:47:22",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "49",
    adiniz_soyadiniz: "Eymen Ata TURAN",
    dogumtarihiniz: "2017-12-26",
    tckimlik: "13060571182",
    iletisimtel: "",
    iletisimvasi: "05063645251",
    iletisimadisoyadi: "Merve Kubilay",
    kayittarihi: "2022-06-13 09:22:12",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "50",
    adiniz_soyadiniz: "İrfan oruç",
    dogumtarihiniz: "2013-10-06",
    tckimlik: "18127714168",
    iletisimtel: "",
    iletisimvasi: "05363513317",
    iletisimadisoyadi: "Filiz oruç",
    kayittarihi: "2022-06-13 11:47:23",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "51",
    adiniz_soyadiniz: "Atlas Şen",
    dogumtarihiniz: "2016-09-14",
    tckimlik: "54949098918",
    iletisimtel: "",
    iletisimvasi: "5336728527",
    iletisimadisoyadi: "Ozan Şen",
    kayittarihi: "2022-06-14 16:28:53",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "53",
    adiniz_soyadiniz: "Artem Bondarchuk",
    dogumtarihiniz: "2014-06-06",
    tckimlik: "99405667680",
    iletisimtel: "",
    iletisimvasi: "0536 475 94 34",
    iletisimadisoyadi: "Olha Bondarchuk",
    kayittarihi: "2022-06-16 09:29:44",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "54",
    adiniz_soyadiniz: "El beren Yolcuoğlu",
    dogumtarihiniz: "2015-03-19",
    tckimlik: "16858443784",
    iletisimtel: "",
    iletisimvasi: "05534938810",
    iletisimadisoyadi: "Halime yolcuoğlu",
    kayittarihi: "2022-06-16 09:46:59",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "55",
    adiniz_soyadiniz: "Merve gönülalan",
    dogumtarihiniz: "1996-05-15",
    tckimlik: "24850921742",
    iletisimtel: "05523520746",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-17 10:07:06",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "56",
    adiniz_soyadiniz: "Fevziye Gülse Atakan",
    dogumtarihiniz: "2014-12-04",
    tckimlik: "13633226242",
    iletisimtel: "",
    iletisimvasi: "05330847995",
    iletisimadisoyadi: "Gülden Ertunç",
    kayittarihi: "2022-06-18 11:11:06",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "57",
    adiniz_soyadiniz: "Ela Gürses",
    dogumtarihiniz: "2012-08-30",
    tckimlik: "29662017058",
    iletisimtel: "",
    iletisimvasi: "5337625938",
    iletisimadisoyadi: "Hüseyin Gürses",
    kayittarihi: "2022-06-18 11:37:10",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "59",
    adiniz_soyadiniz: "Ezgü Gürses",
    dogumtarihiniz: "2008-04-13",
    tckimlik: "11626620138",
    iletisimtel: "",
    iletisimvasi: "5337625938",
    iletisimadisoyadi: "Hüseyin Gürses",
    kayittarihi: "2022-06-18 11:43:22",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "60",
    adiniz_soyadiniz: "Melahat",
    dogumtarihiniz: "2014-08-02",
    tckimlik: "27292095804",
    iletisimtel: "",
    iletisimvasi: "05342918000",
    iletisimadisoyadi: "Şerafettin burgazleer",
    kayittarihi: "2022-06-18 19:06:20",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "62",
    adiniz_soyadiniz: "Beril İNAN",
    dogumtarihiniz: "2012-10-14",
    tckimlik: "26065137550",
    iletisimtel: "",
    iletisimvasi: "05305676167",
    iletisimadisoyadi: "Armağan İNAN",
    kayittarihi: "2022-06-19 08:40:14",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "63",
    adiniz_soyadiniz: "Esra EFETÜRK",
    dogumtarihiniz: "1999-08-11",
    tckimlik: "10432658830",
    iletisimtel: "05525833887",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-19 08:59:04",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "64",
    adiniz_soyadiniz: "Emrullah Ata durşan",
    dogumtarihiniz: "2012-09-28",
    tckimlik: "29659017504",
    iletisimtel: "",
    iletisimvasi: "05062981558",
    iletisimadisoyadi: "Hayal Durşan",
    kayittarihi: "2022-06-19 11:41:17",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "65",
    adiniz_soyadiniz: "Elif  durşan",
    dogumtarihiniz: "2012-09-28",
    tckimlik: "29626018688",
    iletisimtel: "",
    iletisimvasi: "05062981558",
    iletisimadisoyadi: "Hayal Durşan",
    kayittarihi: "2022-06-19 11:43:07",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "66",
    adiniz_soyadiniz: "MEHMET SALİH GEÇMİŞ",
    dogumtarihiniz: "2018-01-16",
    tckimlik: "16720431284",
    iletisimtel: "",
    iletisimvasi: "5053280786",
    iletisimadisoyadi: "GÖKSU GEÇMİŞ",
    kayittarihi: "2022-06-19 12:11:17",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "67",
    adiniz_soyadiniz: "Kübra Demirel",
    dogumtarihiniz: "1998-06-10",
    tckimlik: "37009387030",
    iletisimtel: "05459589961",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-19 20:23:38",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "68",
    adiniz_soyadiniz: "Galip Demirtaş",
    dogumtarihiniz: "2012-05-03",
    tckimlik: "14356551246",
    iletisimtel: "",
    iletisimvasi: "05315902205",
    iletisimadisoyadi: "Selin Demirtaş",
    kayittarihi: "2022-06-21 08:14:09",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "71",
    adiniz_soyadiniz: "Batur Alp Akbay",
    dogumtarihiniz: "2017-03-24",
    tckimlik: "15409492622",
    iletisimtel: "",
    iletisimvasi: "05322215559",
    iletisimadisoyadi: "Tuğçe Özkubat Akbay",
    kayittarihi: "2022-06-22 08:54:44",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "77",
    adiniz_soyadiniz: "Yusuf kerem oğuz",
    dogumtarihiniz: "2014-12-14",
    tckimlik: "24130203162",
    iletisimtel: "",
    iletisimvasi: "05302356060",
    iletisimadisoyadi: "Mehmet Nuri dağdeviren",
    kayittarihi: "2022-06-22 15:25:47",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "78",
    adiniz_soyadiniz: "Hilal Serra gürel",
    dogumtarihiniz: "2012-03-30",
    tckimlik: "13513556414",
    iletisimtel: "",
    iletisimvasi: "05358406388",
    iletisimadisoyadi: "Zeynep gürel",
    kayittarihi: "2022-06-22 18:13:21",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "82",
    adiniz_soyadiniz: "Mayada Elayan",
    dogumtarihiniz: "1977-09-01",
    tckimlik: "98827782428",
    iletisimtel: "05351043133",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-22 18:57:18",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "83",
    adiniz_soyadiniz: "Gökhan ÇETİN",
    dogumtarihiniz: "1995-04-02",
    tckimlik: "26092507180",
    iletisimtel: "5417231818",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-23 08:53:20",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "85",
    adiniz_soyadiniz: "CAN EKEROĞLU",
    dogumtarihiniz: "2011-09-12",
    tckimlik: "12052605584",
    iletisimtel: "",
    iletisimvasi: "5326781874",
    iletisimadisoyadi: "ERCAN EKEROĞLU",
    kayittarihi: "2022-06-23 10:34:09",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "87",
    adiniz_soyadiniz: "Mehmet erden",
    dogumtarihiniz: "1978-01-28",
    tckimlik: "50782836076",
    iletisimtel: "05348522062",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-23 16:34:25",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "90",
    adiniz_soyadiniz: "UMUT BARIŞ KADEŞ",
    dogumtarihiniz: "2008-01-02",
    tckimlik: "11500623200",
    iletisimtel: "",
    iletisimvasi: "05305800556",
    iletisimadisoyadi: "BANU KADEŞ",
    kayittarihi: "2022-06-24 09:52:25",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "91",
    adiniz_soyadiniz: "fatma kertili",
    dogumtarihiniz: "1987-04-01",
    tckimlik: "26668115252",
    iletisimtel: "05309422195",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-24 11:12:13",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "92",
    adiniz_soyadiniz: "ayşe sare kertili",
    dogumtarihiniz: "2016-02-02",
    tckimlik: "25669151498",
    iletisimtel: "",
    iletisimvasi: "05309422195",
    iletisimadisoyadi: "fatma kertili",
    kayittarihi: "2022-06-24 11:14:17",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "93",
    adiniz_soyadiniz: "Yaşar Dora",
    dogumtarihiniz: "2013-02-26",
    tckimlik: "19768349276",
    iletisimtel: "",
    iletisimvasi: "05443785776",
    iletisimadisoyadi: "Üzeyir Dora",
    kayittarihi: "2022-06-24 18:29:01",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "94",
    adiniz_soyadiniz: "Ali Aladağ",
    dogumtarihiniz: "2015-03-19",
    tckimlik: "26080136422",
    iletisimtel: "",
    iletisimvasi: "05395083909",
    iletisimadisoyadi: "Berfin Aladağ",
    kayittarihi: "2022-06-24 20:08:53",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "95",
    adiniz_soyadiniz: "Esra EFETÜRK",
    dogumtarihiniz: "1999-08-11",
    tckimlik: "10432658830",
    iletisimtel: "05525833887",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-24 22:24:33",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "96",
    adiniz_soyadiniz: "Nihat",
    dogumtarihiniz: "2001-04-27",
    tckimlik: "28582052316",
    iletisimtel: "05346844297",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-25 12:45:20",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "97",
    adiniz_soyadiniz: "DUHA NEFES BALTACİ",
    dogumtarihiniz: "2014-07-18",
    tckimlik: "26755113550",
    iletisimtel: "",
    iletisimvasi: "05417672782",
    iletisimadisoyadi: "Gülcan BALTACİ",
    kayittarihi: "2022-06-25 14:09:15",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "98",
    adiniz_soyadiniz: "Miray fişne",
    dogumtarihiniz: "2016-08-25",
    tckimlik: "28981040518",
    iletisimtel: "",
    iletisimvasi: "05348351216",
    iletisimadisoyadi: "Tuba fisne",
    kayittarihi: "2022-06-25 14:09:38",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "99",
    adiniz_soyadiniz: "Yağmur erva fişne",
    dogumtarihiniz: "2012-07-30",
    tckimlik: "28534054214",
    iletisimtel: "",
    iletisimvasi: "05348351216",
    iletisimadisoyadi: "Tuba fisne",
    kayittarihi: "2022-06-25 14:12:02",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "100",
    adiniz_soyadiniz: "TALHA AYAZ BALTACİ",
    dogumtarihiniz: "2017-10-05",
    tckimlik: "13318561514",
    iletisimtel: "",
    iletisimvasi: "05417672782",
    iletisimadisoyadi: "Gülcan BALTACİ",
    kayittarihi: "2022-06-25 14:13:38",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "101",
    adiniz_soyadiniz: "Fikret yılmaz",
    dogumtarihiniz: "1998-03-21",
    tckimlik: "41995439598",
    iletisimtel: "05427194072",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-26 19:28:41",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "102",
    adiniz_soyadiniz: "Asya lina gönülalan",
    dogumtarihiniz: "2019-12-17",
    tckimlik: "19831345464",
    iletisimtel: "",
    iletisimvasi: "05523520746",
    iletisimadisoyadi: "Merve gönülalan",
    kayittarihi: "2022-06-27 09:33:50",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "103",
    adiniz_soyadiniz: "Fatmanur Kahraman",
    dogumtarihiniz: "1998-10-05",
    tckimlik: "29894073686",
    iletisimtel: "5433550393",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-27 10:01:18",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "104",
    adiniz_soyadiniz: "Beril Müderrisoğlu",
    dogumtarihiniz: "2014-10-17",
    tckimlik: "16939442064",
    iletisimtel: "",
    iletisimvasi: "05379344041",
    iletisimadisoyadi: "Esra Müderrisoğlu",
    kayittarihi: "2022-06-27 11:32:58",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "105",
    adiniz_soyadiniz: "KAMİL ERDEM",
    dogumtarihiniz: "1979-03-03",
    tckimlik: "30256762742",
    iletisimtel: "05307104207",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-27 14:08:47",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "106",
    adiniz_soyadiniz: "Hatice Lök",
    dogumtarihiniz: "2022-10-16",
    tckimlik: "14041536688",
    iletisimtel: "5071758041",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-28 06:03:00",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "107",
    adiniz_soyadiniz: "Mehmet Umut Bozkurt",
    dogumtarihiniz: "2010-02-26",
    tckimlik: "22678250204",
    iletisimtel: "",
    iletisimvasi: "05387355028",
    iletisimadisoyadi: "Ramazan Bozkurt",
    kayittarihi: "2022-06-28 08:10:37",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "108",
    adiniz_soyadiniz: "Ahmet aras radıf",
    dogumtarihiniz: "2016-05-30",
    tckimlik: "23380226006",
    iletisimtel: "",
    iletisimvasi: "05369470466",
    iletisimadisoyadi: "Dilek radıf",
    kayittarihi: "2022-06-28 12:39:16",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "109",
    adiniz_soyadiniz: "Ömer yalçın",
    dogumtarihiniz: "2009-08-18",
    tckimlik: "25141798906",
    iletisimtel: "",
    iletisimvasi: "05550314862",
    iletisimadisoyadi: "Adnan yalçın",
    kayittarihi: "2022-06-28 00:00:00",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "110",
    adiniz_soyadiniz: "Berat toksoy",
    dogumtarihiniz: "2022-06-28",
    tckimlik: "2472181994",
    iletisimtel: "",
    iletisimvasi: "05423658091",
    iletisimadisoyadi: "Hatice toksoy",
    kayittarihi: "2022-06-28 14:41:12",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "111",
    adiniz_soyadiniz: "Arzu Özışık",
    dogumtarihiniz: "1974-04-10",
    tckimlik: "12748334316",
    iletisimtel: "5303105180",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-28 18:37:04",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "112",
    adiniz_soyadiniz: "Arzu Özışık",
    dogumtarihiniz: "1974-04-10",
    tckimlik: "12748334316",
    iletisimtel: "5303105180",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-28 18:37:31",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "113",
    adiniz_soyadiniz: "Gulbanu Bolat",
    dogumtarihiniz: "2015-01-07",
    tckimlik: "22522255786",
    iletisimtel: "",
    iletisimvasi: "05531274688",
    iletisimadisoyadi: "Botagoz Bolat",
    kayittarihi: "2022-06-28 00:00:00",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "114",
    adiniz_soyadiniz: "Botagoz Bolat",
    dogumtarihiniz: "1971-09-17",
    tckimlik: "99991370254",
    iletisimtel: "05531274688",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-28 20:23:41",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "115",
    adiniz_soyadiniz: "Ela sağlamel",
    dogumtarihiniz: "2013-10-23",
    tckimlik: "20395328114",
    iletisimtel: "",
    iletisimvasi: "05318369790",
    iletisimadisoyadi: "Dilek Sağlamel",
    kayittarihi: "2022-06-29 06:52:28",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "116",
    adiniz_soyadiniz: "AZra Sağlamel",
    dogumtarihiniz: "2016-05-11",
    tckimlik: "19531357194",
    iletisimtel: "",
    iletisimvasi: "05318369790",
    iletisimadisoyadi: "Dilek Sağlamel",
    kayittarihi: "2022-06-29 06:53:41",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "117",
    adiniz_soyadiniz: "Tuğra kaştaş",
    dogumtarihiniz: "2017-08-24",
    tckimlik: "19582355378",
    iletisimtel: "",
    iletisimvasi: "05324888813",
    iletisimadisoyadi: "Dilek kaştaş",
    kayittarihi: "2022-06-29 11:31:26",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "118",
    adiniz_soyadiniz: "Damla Avinç",
    dogumtarihiniz: "2018-07-13",
    tckimlik: "22525255840",
    iletisimtel: "",
    iletisimvasi: "05366996765",
    iletisimadisoyadi: "Ayşe Avinç",
    kayittarihi: "2022-06-29 12:57:34",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "119",
    adiniz_soyadiniz: "Dilek Özperçin",
    dogumtarihiniz: "1977-03-07",
    tckimlik: "15331305786",
    iletisimtel: "5374201887",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-29 20:50:23",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "120",
    adiniz_soyadiniz: "Seyit Doğan",
    dogumtarihiniz: "1996-08-08",
    tckimlik: "18779009742",
    iletisimtel: "5537256257",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-06-30 07:46:05",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "121",
    adiniz_soyadiniz: "Elif Sude Sarsılmaz",
    dogumtarihiniz: "2007-10-26",
    tckimlik: "12017303074",
    iletisimtel: "",
    iletisimvasi: "05551641861",
    iletisimadisoyadi: "Aliye Sarsılmaz",
    kayittarihi: "2022-06-30 08:12:13",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "1",
  },
  {
    id: "124",
    adiniz_soyadiniz: "Arya Elis ALP",
    dogumtarihiniz: "2017-07-11",
    tckimlik: "23314230390",
    iletisimtel: "",
    iletisimvasi: "05534480487",
    iletisimadisoyadi: "Seher Akkuş ALP",
    kayittarihi: "2022-06-30 10:06:09",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "126",
    adiniz_soyadiniz: "İlyas efe Bozar",
    dogumtarihiniz: "2012-03-09",
    tckimlik: "31120589694",
    iletisimtel: "",
    iletisimvasi: "05511892868",
    iletisimadisoyadi: "Halil bozar",
    kayittarihi: "2022-07-01 08:55:56",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "127",
    adiniz_soyadiniz: "Mehmet Bozar",
    dogumtarihiniz: "2013-08-13",
    tckimlik: "30991595448",
    iletisimtel: "",
    iletisimvasi: "5511892868",
    iletisimadisoyadi: "Halil bozar",
    kayittarihi: "2022-07-01 08:57:45",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "128",
    adiniz_soyadiniz: "Ömer Koçyiğit",
    dogumtarihiniz: "2013-08-25",
    tckimlik: "24739182578",
    iletisimtel: "",
    iletisimvasi: "05369977332",
    iletisimadisoyadi: "Gamze Koçyiğit",
    kayittarihi: "2022-07-01 10:43:28",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "129",
    adiniz_soyadiniz: "Zeynep Koçyiğit",
    dogumtarihiniz: "2015-08-01",
    tckimlik: "14806514234",
    iletisimtel: "",
    iletisimvasi: "05369977332",
    iletisimadisoyadi: "Gamze Koçyiğit",
    kayittarihi: "2022-07-01 10:44:26",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "130",
    adiniz_soyadiniz: "Zeynep Koçyiğit",
    dogumtarihiniz: "2015-08-25",
    tckimlik: "14806514234",
    iletisimtel: "",
    iletisimvasi: "05369977332",
    iletisimadisoyadi: "Gamze Koçyiğit",
    kayittarihi: "2022-07-01 10:44:50",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "131",
    adiniz_soyadiniz: "Ayşe ÇARŞAMBAOĞLU",
    dogumtarihiniz: "2014-07-07",
    tckimlik: "11827613908",
    iletisimtel: "",
    iletisimvasi: "05353704907",
    iletisimadisoyadi: "Fatime ÇARŞAMBAOĞLU",
    kayittarihi: "2022-07-01 15:15:51",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "132",
    adiniz_soyadiniz: "Umar Shamshidenov",
    dogumtarihiniz: "2016-05-27",
    tckimlik: "12064474",
    iletisimtel: "",
    iletisimvasi: "05525262759",
    iletisimadisoyadi: "Azhar",
    kayittarihi: "2022-07-01 18:27:13",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "133",
    adiniz_soyadiniz: "Latifa Shamshidenova",
    dogumtarihiniz: "2017-12-12",
    tckimlik: "12064465",
    iletisimtel: "",
    iletisimvasi: "05525262759",
    iletisimadisoyadi: "Azhar",
    kayittarihi: "2022-07-01 18:29:49",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "134",
    adiniz_soyadiniz: "Mustafa Güven Yaman",
    dogumtarihiniz: "2016-01-01",
    tckimlik: "23515222798",
    iletisimtel: "",
    iletisimvasi: "05557537929",
    iletisimadisoyadi: "Hatice Yaman",
    kayittarihi: "2022-07-01 20:29:34",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "135",
    adiniz_soyadiniz: "Gamze Yağız",
    dogumtarihiniz: "1986-11-06",
    tckimlik: "40421182664",
    iletisimtel: "5309227386",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-02 07:17:30",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "136",
    adiniz_soyadiniz: "Elif sultan Azboy",
    dogumtarihiniz: "2013-02-22",
    tckimlik: "21745280830",
    iletisimtel: "",
    iletisimvasi: "05373427852",
    iletisimadisoyadi: "Mette Rosenberg Azboy",
    kayittarihi: "2022-07-02 07:34:24",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "137",
    adiniz_soyadiniz: "Adem lukas Azboy",
    dogumtarihiniz: "2015-11-14",
    tckimlik: "23632218490",
    iletisimtel: "",
    iletisimvasi: "05373427852",
    iletisimadisoyadi: "Mette Rosenberg Azboy",
    kayittarihi: "2022-07-02 07:35:34",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "138",
    adiniz_soyadiniz: "AYLİN EKEROĞLU",
    dogumtarihiniz: "2011-09-12",
    tckimlik: "12352595530",
    iletisimtel: "",
    iletisimvasi: "5326781874",
    iletisimadisoyadi: "ERCAN EKEROĞLU",
    kayittarihi: "2022-07-02 21:37:03",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "142",
    adiniz_soyadiniz: "Yunus Özkan",
    dogumtarihiniz: "1998-01-14",
    tckimlik: "22657248512",
    iletisimtel: "05528250007",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-04 08:21:22",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "143",
    adiniz_soyadiniz: "Muhammed can Arslan",
    dogumtarihiniz: "2011-11-08",
    tckimlik: "23620220726",
    iletisimtel: "",
    iletisimvasi: "5368792324",
    iletisimadisoyadi: "Habibe",
    kayittarihi: "2022-07-04 09:34:15",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "144",
    adiniz_soyadiniz: "Naime ayşin arslan",
    dogumtarihiniz: "2016-09-08",
    tckimlik: "21343295380",
    iletisimtel: "",
    iletisimvasi: "5368792324",
    iletisimadisoyadi: "Habibe",
    kayittarihi: "2022-07-04 09:35:40",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "147",
    adiniz_soyadiniz: "Yusuf şahin eye",
    dogumtarihiniz: "2015-06-17",
    tckimlik: "22765247926",
    iletisimtel: "",
    iletisimvasi: "05443441069",
    iletisimadisoyadi: "Şenay Türker",
    kayittarihi: "2022-07-04 09:51:50",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "149",
    adiniz_soyadiniz: "Gülfem Sena Uysal",
    dogumtarihiniz: "2015-02-12",
    tckimlik: "12964573018",
    iletisimtel: "",
    iletisimvasi: "05079739069",
    iletisimadisoyadi: "Selma Uysal",
    kayittarihi: "2022-07-05 06:14:57",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "150",
    adiniz_soyadiniz: "Elif Yüksel",
    dogumtarihiniz: "2012-08-17",
    tckimlik: "25702149000",
    iletisimtel: "",
    iletisimvasi: "5364245443",
    iletisimadisoyadi: "Özlem Yüksel",
    kayittarihi: "2022-07-05 09:59:49",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "151",
    adiniz_soyadiniz: "Turgay yılmaz",
    dogumtarihiniz: "1994-05-27",
    tckimlik: "21853275452",
    iletisimtel: "05444407707",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-06 10:48:46",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "1",
  },
  {
    id: "152",
    adiniz_soyadiniz: "Ayşe Beril yıldırım",
    dogumtarihiniz: "2017-04-09",
    tckimlik: "25033203716",
    iletisimtel: "",
    iletisimvasi: "05468536164",
    iletisimadisoyadi: "Hayriye yıldırım",
    kayittarihi: "2022-07-06 11:27:58",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "153",
    adiniz_soyadiniz: "Mina ünal",
    dogumtarihiniz: "2010-11-03",
    tckimlik: "23179234090",
    iletisimtel: "",
    iletisimvasi: "05468536164",
    iletisimadisoyadi: "Hayriye yıldırım",
    kayittarihi: "2022-07-06 11:30:05",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "154",
    adiniz_soyadiniz: "Eda demirtaşlı",
    dogumtarihiniz: "2015-05-12",
    tckimlik: "13966540756",
    iletisimtel: "",
    iletisimvasi: "05468536164",
    iletisimadisoyadi: "Hayriye yıldırım",
    kayittarihi: "2022-07-06 11:31:58",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "155",
    adiniz_soyadiniz: "Ayşe Aktekin",
    dogumtarihiniz: "2015-09-28",
    tckimlik: "13426557676",
    iletisimtel: "",
    iletisimvasi: "5536017353",
    iletisimadisoyadi: "Bedia aktekin",
    kayittarihi: "2022-07-07 11:49:08",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "156",
    adiniz_soyadiniz: "Bedia aktekin",
    dogumtarihiniz: "1992-09-28",
    tckimlik: "35453315166",
    iletisimtel: "5536017353",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-07 11:49:57",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "159",
    adiniz_soyadiniz: "Ceylan ziyalar",
    dogumtarihiniz: "1994-10-15",
    tckimlik: "30742545774",
    iletisimtel: "05454652896",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-10 13:25:43",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "160",
    adiniz_soyadiniz: "Ceylan ziyalar",
    dogumtarihiniz: "1994-10-15",
    tckimlik: "30742545774",
    iletisimtel: "05454652896",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-10 14:53:00",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "1",
  },
  {
    id: "161",
    adiniz_soyadiniz: "Ela Beren Yolcuoğlu",
    dogumtarihiniz: "2015-03-19",
    tckimlik: "16858443784",
    iletisimtel: "",
    iletisimvasi: "05534938810",
    iletisimadisoyadi: "Halime    Yolcuoğlu",
    kayittarihi: "2022-07-13 13:44:29",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "1",
  },
  {
    id: "162",
    adiniz_soyadiniz: "Ceren ziyalar",
    dogumtarihiniz: "2003-06-28",
    tckimlik: "10259228964",
    iletisimtel: "05315021521",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-13 13:46:35",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "1",
  },
  {
    id: "163",
    adiniz_soyadiniz: "Ömer Aydın",
    dogumtarihiniz: "2010-06-25",
    tckimlik: "43472122184",
    iletisimtel: "",
    iletisimvasi: "05316922029",
    iletisimadisoyadi: "Muhammet Aydın",
    kayittarihi: "2022-07-14 11:26:36",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "164",
    adiniz_soyadiniz: "Sema Aydın",
    dogumtarihiniz: "2013-10-07",
    tckimlik: "43469122258",
    iletisimtel: "",
    iletisimvasi: "05316922029",
    iletisimadisoyadi: "Muhammet Aydın",
    kayittarihi: "2022-07-14 00:00:00",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "165",
    adiniz_soyadiniz: "ADA BAŞHAN",
    dogumtarihiniz: "2016-06-13",
    tckimlik: "21910275210",
    iletisimtel: "",
    iletisimvasi: "05326882448",
    iletisimadisoyadi: "HAKAN BAŞHAN",
    kayittarihi: "2022-07-15 10:00:07",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "166",
    adiniz_soyadiniz: "Melisa Tatar",
    dogumtarihiniz: "2018-02-22",
    tckimlik: "19243365890",
    iletisimtel: "",
    iletisimvasi: "05553667810",
    iletisimadisoyadi: "Sezin Ateş Tatar",
    kayittarihi: "2022-07-15 11:39:04",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "169",
    adiniz_soyadiniz: "Arda Kahveci",
    dogumtarihiniz: "2000-05-29",
    tckimlik: "11111111111",
    iletisimtel: "5421803282",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-16 10:25:59",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "170",
    adiniz_soyadiniz: "SERDAR KADI",
    dogumtarihiniz: "1986-01-16",
    tckimlik: "18415698002",
    iletisimtel: "5378939594",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-16 18:18:08",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "171",
    adiniz_soyadiniz: "Erol Din Hrnjadovic",
    dogumtarihiniz: "2015-11-14",
    tckimlik: "99825896882",
    iletisimtel: "",
    iletisimvasi: "05364847935",
    iletisimadisoyadi: "Danijela Hrnjadovic",
    kayittarihi: "2022-07-17 19:12:48",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "172",
    adiniz_soyadiniz: "Erol Din Hrnjadovic",
    dogumtarihiniz: "2015-11-14",
    tckimlik: "99825896882",
    iletisimtel: "",
    iletisimvasi: "05364847935",
    iletisimadisoyadi: "Danijela Hrnjadovic",
    kayittarihi: "2022-07-17 19:14:05",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "173",
    adiniz_soyadiniz: "Mirza Yıldırım",
    dogumtarihiniz: "2011-02-14",
    tckimlik: "22096271324",
    iletisimtel: "",
    iletisimvasi: "05343050075",
    iletisimadisoyadi: "Neslihan yıldırım",
    kayittarihi: "2022-07-18 08:51:47",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "174",
    adiniz_soyadiniz: "Ezel Yıldırım",
    dogumtarihiniz: "2013-05-14",
    tckimlik: "27667083740",
    iletisimtel: "",
    iletisimvasi: "05343050075",
    iletisimadisoyadi: "Neslihan yıldırım",
    kayittarihi: "2022-07-18 08:54:15",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "175",
    adiniz_soyadiniz: "Hasn\\'t Salam hameed",
    dogumtarihiniz: "2015-01-01",
    tckimlik: "99321699604",
    iletisimtel: "",
    iletisimvasi: "05012489285",
    iletisimadisoyadi: "Salam husien",
    kayittarihi: "2022-07-18 10:17:49",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "176",
    adiniz_soyadiniz: "Anna Pshehalouk",
    dogumtarihiniz: "2012-01-25",
    tckimlik: "99018753732",
    iletisimtel: "",
    iletisimvasi: "05387130317",
    iletisimadisoyadi: "Deanna pshehalouk",
    kayittarihi: "2022-07-18 10:28:12",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "177",
    adiniz_soyadiniz: "Beren Ertunç",
    dogumtarihiniz: "2019-04-07",
    tckimlik: "16999440192",
    iletisimtel: "",
    iletisimvasi: "05378589244",
    iletisimadisoyadi: "Ünsal Ertunç",
    kayittarihi: "2022-07-18 10:59:41",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "178",
    adiniz_soyadiniz: "Hasan salam",
    dogumtarihiniz: "2022-07-18",
    tckimlik: "263748374",
    iletisimtel: "",
    iletisimvasi: "08565646",
    iletisimadisoyadi: "Ai",
    kayittarihi: "2022-07-18 11:48:39",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "179",
    adiniz_soyadiniz: "Yasemin büyükberber",
    dogumtarihiniz: "1970-06-22",
    tckimlik: "32875833100",
    iletisimtel: "05521226005",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-19 07:16:17",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "180",
    adiniz_soyadiniz: "Belinay Yalçıner",
    dogumtarihiniz: "2013-12-20",
    tckimlik: "48802928832",
    iletisimtel: "",
    iletisimvasi: "05321799440",
    iletisimadisoyadi: "Özlem Yalçıner",
    kayittarihi: "2022-07-19 09:46:07",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "181",
    adiniz_soyadiniz: "Belinay Yalçıner",
    dogumtarihiniz: "2013-12-20",
    tckimlik: "48802928832",
    iletisimtel: "",
    iletisimvasi: "05321799440",
    iletisimadisoyadi: "Özlem Yalçıner",
    kayittarihi: "2022-07-19 09:49:41",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "183",
    adiniz_soyadiniz: "Ahmet eymen arslan",
    dogumtarihiniz: "2014-03-24",
    tckimlik: "19066371474",
    iletisimtel: "",
    iletisimvasi: "05321360266",
    iletisimadisoyadi: "Gulcihan arslan",
    kayittarihi: "2022-07-19 17:01:23",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "184",
    adiniz_soyadiniz: "Beren Ertunç",
    dogumtarihiniz: "2019-04-07",
    tckimlik: "16999440192",
    iletisimtel: "",
    iletisimvasi: "05378589244",
    iletisimadisoyadi: "Ünsal Ertunç",
    kayittarihi: "2022-07-20 09:13:31",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "186",
    adiniz_soyadiniz: "Havin Yildirim",
    dogumtarihiniz: "2012-01-09",
    tckimlik: "15787479536",
    iletisimtel: "",
    iletisimvasi: "05076439542",
    iletisimadisoyadi: "Habip Yildirim",
    kayittarihi: "2022-07-20 09:39:45",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "187",
    adiniz_soyadiniz: "Kıvanç Yildirim",
    dogumtarihiniz: "2018-08-25",
    tckimlik: "29506023726",
    iletisimtel: "",
    iletisimvasi: "05076439542",
    iletisimadisoyadi: "Habip Yildirim",
    kayittarihi: "2022-07-20 09:41:16",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "188",
    adiniz_soyadiniz: "Bedirhan sel",
    dogumtarihiniz: "2017-11-03",
    tckimlik: "12139601362",
    iletisimtel: "",
    iletisimvasi: "05364407262",
    iletisimadisoyadi: "Semra sel",
    kayittarihi: "2022-07-20 10:29:18",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "190",
    adiniz_soyadiniz: "Melis Aslan",
    dogumtarihiniz: "2016-04-08",
    tckimlik: "27430093570",
    iletisimtel: "",
    iletisimvasi: "05388793851",
    iletisimadisoyadi: "Şükran Aslan",
    kayittarihi: "2022-07-20 12:47:53",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "191",
    adiniz_soyadiniz: "Özlem canatan",
    dogumtarihiniz: "2022-05-10",
    tckimlik: "16931447128",
    iletisimtel: "05558012556",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-20 19:18:34",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "192",
    adiniz_soyadiniz: "Perin Kaya",
    dogumtarihiniz: "2017-03-21",
    tckimlik: "15418492330",
    iletisimtel: "05066524927",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-20 00:00:00",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "193",
    adiniz_soyadiniz: "Duru yavuz",
    dogumtarihiniz: "2016-07-11",
    tckimlik: "30025005230",
    iletisimtel: "",
    iletisimvasi: "05327422276",
    iletisimadisoyadi: "songül yavuz",
    kayittarihi: "2022-07-20 00:00:00",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "194",
    adiniz_soyadiniz: "Emir halil yavuz",
    dogumtarihiniz: "2018-08-03",
    tckimlik: "30133002732",
    iletisimtel: "",
    iletisimvasi: "05327422276",
    iletisimadisoyadi: "songül yavuz",
    kayittarihi: "2022-07-20 22:24:12",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "195",
    adiniz_soyadiniz: "Eda Bestesu Akzayim",
    dogumtarihiniz: "2012-03-24",
    tckimlik: "15835478900",
    iletisimtel: "",
    iletisimvasi: "05435757851",
    iletisimadisoyadi: "Zümral Akzayim",
    kayittarihi: "2022-07-21 00:00:00",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "197",
    adiniz_soyadiniz: "Alper Algan",
    dogumtarihiniz: "2004-10-30",
    tckimlik: "10036862220",
    iletisimtel: "05525520711",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-23 06:17:15",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "198",
    adiniz_soyadiniz: "Kerem Kaya",
    dogumtarihiniz: "2015-04-09",
    tckimlik: "22864243898",
    iletisimtel: "",
    iletisimvasi: "05366014303",
    iletisimadisoyadi: "Tuğba Kaya",
    kayittarihi: "2022-07-23 10:21:24",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "199",
    adiniz_soyadiniz: "Muhammed atabey ibiş",
    dogumtarihiniz: "2011-04-24",
    tckimlik: "29507565560",
    iletisimtel: "",
    iletisimvasi: "05067480166",
    iletisimadisoyadi: "Sunay İbis",
    kayittarihi: "2022-07-24 10:50:06",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "200",
    adiniz_soyadiniz: "Ahmed Kayıbey İBİŞ",
    dogumtarihiniz: "2015-06-03",
    tckimlik: "12345678901",
    iletisimtel: "",
    iletisimvasi: "05067480166",
    iletisimadisoyadi: "Sunay İbis",
    kayittarihi: "2022-07-24 10:53:02",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "201",
    adiniz_soyadiniz: "Fatmanur peri",
    dogumtarihiniz: "2009-03-14",
    tckimlik: "20728315828",
    iletisimtel: "",
    iletisimvasi: "5312355029",
    iletisimadisoyadi: "Selda peri",
    kayittarihi: "2022-07-24 14:37:28",
    uyeliktip: "2",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "202",
    adiniz_soyadiniz: "Sona Rahimova",
    dogumtarihiniz: "1984-06-06",
    tckimlik: "99810666858",
    iletisimtel: "05343018098",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-24 00:00:00",
    uyeliktip: "1",
    cinsiyet: "0",
    durum: "0",
  },
  {
    id: "223",
    adiniz_soyadiniz: "Aybüke Ece Mercan",
    dogumtarihiniz: "2012-10-1",
    tckimlik: "20614319084",
    iletisimtel: "",
    iletisimvasi: "05396228008",
    iletisimadisoyadi: "Günel Mercan",
    kayittarihi: "2022-07-25 08:34:08",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "226",
    adiniz_soyadiniz: "Ömer taze",
    dogumtarihiniz: "2007-5-18",
    tckimlik: "11197634978",
    iletisimtel: "",
    iletisimvasi: "5306070454",
    iletisimadisoyadi: "Mehmet taze",
    kayittarihi: "2022-07-25 10:38:19",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "227",
    adiniz_soyadiniz: "Burak Karataş",
    dogumtarihiniz: "2006-10-17",
    tckimlik: "11354315542",
    iletisimtel: "",
    iletisimvasi: "5303070454",
    iletisimadisoyadi: "Mehmet taze",
    kayittarihi: "2022-07-25 10:43:15",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "228",
    adiniz_soyadiniz: "zümra zahide yeşilkılınç",
    dogumtarihiniz: "2018-05-05",
    tckimlik: "21658293286",
    iletisimtel: "",
    iletisimvasi: "05325069266",
    iletisimadisoyadi: "Naciye Bilgin Yeşilkılınç",
    kayittarihi: "2022-07-25 00:00:00",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "229",
    adiniz_soyadiniz: "Beren Naz Yeşilkılınç",
    dogumtarihiniz: "2014-10-10",
    tckimlik: "10912652624",
    iletisimtel: "",
    iletisimvasi: "05325409646",
    iletisimadisoyadi: "Ahmet Yeşilkılınç",
    kayittarihi: "2022-07-25 13:31:58",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "230",
    adiniz_soyadiniz: "CEREN öZKOç",
    dogumtarihiniz: "2001-8-25",
    tckimlik: "39757419094",
    iletisimtel: "",
    iletisimvasi: "05428101889",
    iletisimadisoyadi: "BARıS CEM",
    kayittarihi: "2022-07-25 16:36:51",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "231",
    adiniz_soyadiniz: "RAMAZAN DEğIRMENCI",
    dogumtarihiniz: "2001-1-1",
    tckimlik: "20689325386",
    iletisimtel: "05076364736",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-26 08:39:40",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "233",
    adiniz_soyadiniz: "NISA KARABACAK",
    dogumtarihiniz: "2010-12-6",
    tckimlik: "23515223238",
    iletisimtel: "",
    iletisimvasi: "5070040333",
    iletisimadisoyadi: "HALIL KARABACAK",
    kayittarihi: "2022-07-26 09:58:23",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "1",
  },
  {
    id: "236",
    adiniz_soyadiniz: "PERESKOKOVA AGATA",
    dogumtarihiniz: "2018-4-15",
    tckimlik: "7280783700",
    iletisimtel: "",
    iletisimvasi: " 905461165323",
    iletisimadisoyadi: "NAGIBINA JULIA",
    kayittarihi: "2022-07-27 14:59:37",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "237",
    adiniz_soyadiniz: "MUSA GÜNAY",
    dogumtarihiniz: "1990-11-10",
    tckimlik: "28345072360",
    iletisimtel: "5352265291",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-27 20:40:17",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "238",
    adiniz_soyadiniz: "ZEYNEP AZRA YALÇIN",
    dogumtarihiniz: "2014-1-27",
    tckimlik: "23683216742",
    iletisimtel: "",
    iletisimvasi: "05492110505",
    iletisimadisoyadi: "FEYZA YALÇIN",
    kayittarihi: "2022-07-28 09:55:56",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "239",
    adiniz_soyadiniz: "GÜLBERIL PIŞKIN",
    dogumtarihiniz: "2012-12-12",
    tckimlik: "29695017346",
    iletisimtel: "",
    iletisimvasi: "05074577443",
    iletisimadisoyadi: "KUBRA PIŞKIN",
    kayittarihi: "2022-07-28 00:00:00",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "241",
    adiniz_soyadiniz: "ERDEM GÖKMEN",
    dogumtarihiniz: "1996-2-21",
    tckimlik: "12835586468",
    iletisimtel: "05458300707",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-29 10:45:25",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "242",
    adiniz_soyadiniz: "MEHMET EYMEN SENER",
    dogumtarihiniz: "2014-1-14",
    tckimlik: "64645401370",
    iletisimtel: "",
    iletisimvasi: "5413717329",
    iletisimadisoyadi: "OGUZ SENER",
    kayittarihi: "2022-07-29 15:39:16",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "243",
    adiniz_soyadiniz: "KAYRA ŪNAL",
    dogumtarihiniz: "2012-10-25",
    tckimlik: "16747448258",
    iletisimtel: "",
    iletisimvasi: "05468536164",
    iletisimadisoyadi: "HAYRIYE YILDIRIM",
    kayittarihi: "2022-07-29 18:02:26",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "1",
  },
  {
    id: "245",
    adiniz_soyadiniz: "EMIR EFE KAYA",
    dogumtarihiniz: "2017-12-17",
    tckimlik: "12676583862",
    iletisimtel: "",
    iletisimvasi: "05444843708",
    iletisimadisoyadi: "BERNA KAYA",
    kayittarihi: "2022-07-30 05:48:50",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "246",
    adiniz_soyadiniz: "BURCU BILEN",
    dogumtarihiniz: "1990-1-14",
    tckimlik: "47068688962",
    iletisimtel: "5068393924",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-30 09:59:08",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "247",
    adiniz_soyadiniz: "ZHULDYZ SAKIYEVA",
    dogumtarihiniz: "1991-3-11",
    tckimlik: "99321790066",
    iletisimtel: "05342635088",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-30 13:29:46",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "248",
    adiniz_soyadiniz: "YERMEK SAKIYEV",
    dogumtarihiniz: "1988-7-27",
    tckimlik: "99966845602",
    iletisimtel: "05441715847",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-07-30 13:30:53",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "249",
    adiniz_soyadiniz: "АLI ABISIV",
    dogumtarihiniz: "2017-2-5",
    tckimlik: "00000000000",
    iletisimtel: "",
    iletisimvasi: "05387108267",
    iletisimadisoyadi: "ALIYA RAKHIMZHANOVA",
    kayittarihi: "2022-07-31 10:00:26",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "250",
    adiniz_soyadiniz: "TUĞBA YILDIZ",
    dogumtarihiniz: "1994-8-25",
    tckimlik: "25333028668",
    iletisimtel: "5303942508",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-01 10:00:39",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "251",
    adiniz_soyadiniz: "CINDY NAYEBI",
    dogumtarihiniz: "2001-09-14",
    tckimlik: "99201814936",
    iletisimtel: "05527815795",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-01 00:00:00",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "252",
    adiniz_soyadiniz: "OMUD I NIDHAMALDIN",
    dogumtarihiniz: "2017-11-7",
    tckimlik: "99039186588",
    iletisimtel: "",
    iletisimvasi: "05538859204",
    iletisimadisoyadi: "NOORDAN NAJM",
    kayittarihi: "2022-08-02 08:27:39",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "253",
    adiniz_soyadiniz: "ARAS IMAN",
    dogumtarihiniz: "2016-12-3",
    tckimlik: "02970603",
    iletisimtel: "",
    iletisimvasi: " 447444889585",
    iletisimadisoyadi: "NIGAR SAFAROVA",
    kayittarihi: "2022-08-03 10:02:36",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "254",
    adiniz_soyadiniz: "EMINE NEHIR AKILLI",
    dogumtarihiniz: "2013-1-1",
    tckimlik: "24169201702",
    iletisimtel: "",
    iletisimvasi: "05554198525",
    iletisimadisoyadi: "CANAN AKILLI",
    kayittarihi: "2022-08-03 13:14:45",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "255",
    adiniz_soyadiniz: "GÖK DENIZ AZRAK",
    dogumtarihiniz: "2012-12-14",
    tckimlik: "23530222896",
    iletisimtel: "",
    iletisimvasi: "5557546324",
    iletisimadisoyadi: "SEDA MAKULOĞLU AZRAK",
    kayittarihi: "2022-08-03 18:43:41",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "256",
    adiniz_soyadiniz: "ÖZLEM ŞEN",
    dogumtarihiniz: "1997-10-9",
    tckimlik: "10484446720",
    iletisimtel: "05417180758",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-03 18:51:49",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "257",
    adiniz_soyadiniz: "SEDAT ELÇI",
    dogumtarihiniz: "2012-8-4",
    tckimlik: "17893408998",
    iletisimtel: "",
    iletisimvasi: "05386472059",
    iletisimadisoyadi: "SEHER ELÇI",
    kayittarihi: "2022-08-04 08:14:30",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "258",
    adiniz_soyadiniz: "AMINE AMBER HANEDAN",
    dogumtarihiniz: "2017-4-25",
    tckimlik: "29005039884",
    iletisimtel: "",
    iletisimvasi: "05417626254",
    iletisimadisoyadi: "RABIA HANEDAN",
    kayittarihi: "2022-08-07 15:43:34",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "259",
    adiniz_soyadiniz: "BEYZA BORAZAN",
    dogumtarihiniz: "2001-6-10",
    tckimlik: "58519212902",
    iletisimtel: "5533821829",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-08 08:46:44",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "260",
    adiniz_soyadiniz: "MERT DENIZ KULAÇ",
    dogumtarihiniz: "2007-8-13",
    tckimlik: "11323629670",
    iletisimtel: "5511788767",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-10 11:28:17",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "261",
    adiniz_soyadiniz: "YÜCEL SARP KILIÇARSLAN",
    dogumtarihiniz: "2006-8-14",
    tckimlik: "10858643892",
    iletisimtel: "",
    iletisimvasi: "05057070187",
    iletisimadisoyadi: "YUSUF KILIÇARSLAN",
    kayittarihi: "2022-08-10 11:54:45",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "262",
    adiniz_soyadiniz: "MERIH ŞAHIN",
    dogumtarihiniz: "2015-4-27",
    tckimlik: "43922101158",
    iletisimtel: "",
    iletisimvasi: "05079711270",
    iletisimadisoyadi: "MELIS ŞAHIN",
    kayittarihi: "2022-08-10 16:05:38",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "263",
    adiniz_soyadiniz: "MARINA KALYKOVA",
    dogumtarihiniz: "1988-12-10",
    tckimlik: "99176698746",
    iletisimtel: "05365204140",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-10 22:02:40",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "264",
    adiniz_soyadiniz: "DANIIL KONOPLIN",
    dogumtarihiniz: "2017-1-4",
    tckimlik: "99039932284",
    iletisimtel: "",
    iletisimvasi: "05364097602",
    iletisimadisoyadi: "DENYS KONOPLIN",
    kayittarihi: "2022-08-13 18:40:10",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "265",
    adiniz_soyadiniz: "ERSOY ÇETIN",
    dogumtarihiniz: "1964-3-15",
    tckimlik: "21307306428",
    iletisimtel: "05057070530",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-14 09:59:10",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "266",
    adiniz_soyadiniz: "FAIKA ÇETIN",
    dogumtarihiniz: "1971-12-1",
    tckimlik: "21058314734",
    iletisimtel: "05057070540",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-14 10:01:41",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "267",
    adiniz_soyadiniz: "YAZEL HIRA",
    dogumtarihiniz: "2015-2-27",
    tckimlik: "32834476096",
    iletisimtel: "",
    iletisimvasi: "1",
    iletisimadisoyadi: "ANNESI NEVIN SERBEST",
    kayittarihi: "2022-08-15 10:16:25",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "268",
    adiniz_soyadiniz: "MARINA",
    dogumtarihiniz: "1988-12-10",
    tckimlik: "99176698746",
    iletisimtel: "05365204148",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-15 19:38:10",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "269",
    adiniz_soyadiniz: "MARINA KALYKOVA",
    dogumtarihiniz: "1988-12-10",
    tckimlik: "99117669874",
    iletisimtel: "05365204148",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-15 19:40:48",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "270",
    adiniz_soyadiniz: "AYŞE ZÜMRA SONMEZ",
    dogumtarihiniz: "2014-3-1",
    tckimlik: "12898576680",
    iletisimtel: "",
    iletisimvasi: "5349118383",
    iletisimadisoyadi: "AYÇA SONMEZ",
    kayittarihi: "2022-08-17 10:03:04",
    uyeliktip: "2",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "271",
    adiniz_soyadiniz: "HAMZA ALI SONMEZ",
    dogumtarihiniz: "2017-8-8",
    tckimlik: "13654552696",
    iletisimtel: "",
    iletisimvasi: "5349118383",
    iletisimadisoyadi: "AYÇA SONMEZ",
    kayittarihi: "2022-08-17 10:05:25",
    uyeliktip: "2",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "272",
    adiniz_soyadiniz: "MURAT TEKIN ÇAMALAN",
    dogumtarihiniz: "1969-5-5",
    tckimlik: "12940095022",
    iletisimtel: "05079407548",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-17 10:12:25",
    uyeliktip: "1",
    cinsiyet: "1",
    durum: "0",
  },
  {
    id: "273",
    adiniz_soyadiniz: "AYFER ÇAMALAN",
    dogumtarihiniz: "1973-12-10",
    tckimlik: "12112122686",
    iletisimtel: "05079407547",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-17 10:13:37",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
  {
    id: "274",
    adiniz_soyadiniz: "ROJBIN DUMAN",
    dogumtarihiniz: "2000-12-26",
    tckimlik: "30958657834",
    iletisimtel: "05413362635",
    iletisimvasi: "",
    iletisimadisoyadi: "",
    kayittarihi: "2022-08-17 11:34:29",
    uyeliktip: "1",
    cinsiyet: "2",
    durum: "0",
  },
];
