import React from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Expired from "./pages/Expired";
import Registered from "./pages/Registered";

function App() {
  return (
    <>
      <Toaster />
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/expired" element={<Expired />} />
        <Route path="/registered" element={<Registered />} />
      </Routes>
    </>
  );
}

export default App;
