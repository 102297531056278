import React, { useState } from "react";
import { BiLogOutCircle, BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../firebase";
import { logout as logoutHandle } from "../store/auth";
import { links } from "../data/data";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logout();
    dispatch(logoutHandle());
    navigate("/", {
      replace: true,
    });
  };

  const { user } = useSelector((state) => state.auth);

  return (
    <div className="min-w-screen bg-[#2560d4] p-4 shadow-lg mb-4">
      <div className="w-full flex items-center justify-between ">
        <Link to="/">
          <div className="bg-white p-1 rounded-full">
            <img
              src="https://wakeswimacademy.com/yeni/tema/logo.png"
              alt="logo"
              className="w-20 h-20 object-cover"
            />
          </div>
        </Link>
        <div>
          <button
            type="button"
            className="text-white"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu ? (
              <AiOutlineClose className="w-6 h-6" />
            ) : (
              <BiMenu className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>

      <div
        className={`${
          openMenu ? "left-0" : "-left-2/3"
        } absolute top-0 left-0 w-2/3 bg-white h-screen transition-all duration-200 pt-12`}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className="flex flex-col gap-6 pl-4">
            {links.map((link, index) => (
              <Link
                to={link.path}
                key={index}
                className="flex items-center gap-4"
              >
                <span className="text-xl">{link.icon}</span>
                <span>{link.name}</span>
              </Link>
            ))}
            <div>
              <button
                type="button"
                onClick={handleLogout}
                className="flex items-center gap-4 text-red-500"
              >
                <span>
                  <BiLogOutCircle className="text-xl" />
                </span>
                <span>Çıkış Yap</span>
              </button>
            </div>
          </div>
          <div className="w-full bg-[#2560d4] p-4 flex items-center justify-between gap-2">
            <div className="bg-white p-0.5 rounded-full">
              <img
                src="https://wakeswimacademy.com/yeni/tema/logo.png"
                alt=""
              />
            </div>
            <span className="text-sm text-white">{user.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
