import React from "react";
import { Link } from "react-router-dom";

const MenuCard = ({ icon, path, title, color1, color2 }) => {
  return (
    <div
      className={`max-w-32 w-36 h-36 rounded-lg shadow-2xl`}
      style={{ background: `linear-gradient(to right, ${color1}, ${color2})` }}
    >
      <Link
        to={path}
        className="w-full h-full flex flex-col items-start justify-between p-4"
      >
        <div className="text-3xl text-white">{icon}</div>
        <p className="text-white text-lg font-semibold">{title}</p>
      </Link>
    </div>
  );
};

export default MenuCard;
