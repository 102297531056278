import React, { useState } from "react";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { expired } from "../data/data";

const ExpiredTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [filter, setFilter] = useState([...expired]);
  const [currentDate, setCurrentDate] = useState(
    `${("0" + (new Date().getMonth() + 1)).slice(
      -2
    )}/${new Date().getDate()}/${new Date().getFullYear()}`
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const requestSearch = (searchVal) => {
    const filteredRows = expired.filter((row) => {
      return row.sinif
        .toString()
        .toLowerCase()
        .includes(searchVal.toString().toLowerCase());
    });
    if (searchVal.length < 1) {
      setFilter([...expired]);
    } else {
      setFilter(filteredRows);
    }
  };

  const handleChange = (e) => {
    setCurrentDate(e.target.value);
  };
  const handleClick = (event) => {
    event.preventDefault();
    console.log("handle click:", currentDate);
  };

  return (
    <div>
      <div className="flex items-center justify-center gap-2 w-5/6 mx-auto mb-4">
        <input
          type="date"
          id="date"
          onChange={handleChange}
          className="block px-4 py-2 pl-2 w-2/3 mx-auto text-left text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          value={`${currentDate.slice(-4)}-${currentDate.slice(
            0,
            2
          )}-${currentDate.slice(3, 5)}`}
        />
        <button
          type="submit"
          className="bg-blue-500 rounded px-4 py-2 text-white text-md"
          onClick={handleClick}
        >
          Tamam
        </button>
      </div>
      <div>
        <input
          type="search"
          id="search"
          className="block mb-4 px-4 py-2 pl-10 w-5/6 mx-auto text-center text-sm text-gray-900 bg-gray-50 rounded outline-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
          placeholder="Arama yapmak için yaz..."
          onChange={(e) => requestSearch(e.target.value)}
        />
      </div>
      {/*<div className={'text-center mb-4'}>Toplam kayit sayisi: {expired.length}</div>*/}
      <table className="w-full">
        <thead className="text-sm w-full">
          <tr className="flex items-center justify-center text-center bg-gray-200 mb-1 border-b border-b-solid border-b-gray-400 w-full pl-1">
            <th className="w-1/6 py-4 font-medium">Detay</th>
            {/* <th className="w-1/3 py-4 font-medium">Sınıf</th> */}
            <th className="w-1/2 py-4 font-medium">Ad Soyad</th>
            <th className="w-1/3 py-4 font-medium">Bitiş Tarihi</th>
          </tr>
        </thead>
        <tbody
          className="flex flex-col items-center justify-between overflow-y-scroll w-full"
          style={{ height: "50vh" }}
        >
          {filter.map((item, index) => {
            let endDate = new Date(
              `${item.bitis_tarih[5]}${item.bitis_tarih[6]}/${item.bitis_tarih[8]}${item.bitis_tarih[9]}/${item.bitis_tarih[0]}${item.bitis_tarih[1]}${item.bitis_tarih[2]}${item.bitis_tarih[3]}`
            );

            let diff = endDate.getTime() - new Date(currentDate).getTime();
            let days = Math.ceil(diff / (1000 * 3600 * 24));
            return (
              <>
                <tr
                  key={index}
                  className={`flex items-center w-full border-b border-b-solid border-b-green-800 mb-1 py-2 ${
                    days <= 0
                      ? "bg-red-200"
                      : days <= 10
                      ? "bg-yellow-200"
                      : "bg-green-200"
                  }`}
                  onMouseUp={() => {
                    setSelectedId(index);
                  }}
                >
                  <td className="w-1/6 flex items-center justify-center text-gray-700">
                    <button
                      type="button"
                      className="text-2xl transition-all duration-200"
                      onClick={toggle}
                    >
                      {isOpen && selectedId === index ? (
                        <AiFillMinusCircle />
                      ) : (
                        <AiFillPlusCircle />
                      )}
                    </button>
                  </td>
                  {/* <td className="py-2 w-1/3 text-sm text-center">
                    {item.sinif}
                  </td> */}
                  <td className="py-2 w-1/2 text-sm text-center">
                    {item.adiniz_soyadiniz.toUpperCase()}
                  </td>
                  <td className="py-2 w-1/3 text-center">{`${item.bitis_tarih.slice(
                    8,
                    10
                  )}/${item.bitis_tarih.slice(5, 7)}/${item.bitis_tarih.slice(
                    0,
                    4
                  )}`}</td>
                </tr>
                <div
                  className={`${
                    isOpen && selectedId === index
                      ? "flex items-center justify-between w-full mb-1"
                      : "hidden"
                  } p-4 transition-all duration-500`}
                >
                  <div className="flex flex-col gap-1 text-sm">
                    <div className="flex items-center justify-between">
                      <span className="font-semibold">Başlama Tarihi:</span>
                      <span className="ml-2">{`${item.baslama_tarih.slice(
                        8,
                        10
                      )}/${item.baslama_tarih.slice(
                        5,
                        7
                      )}/${item.baslama_tarih.slice(0, 4)}`}</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="font-semibold">Bitiş Tarihi:</span>
                      <span>{`${item.bitis_tarih.slice(
                        8,
                        10
                      )}/${item.bitis_tarih.slice(
                        5,
                        7
                      )}/${item.bitis_tarih.slice(0, 4)}`}</span>
                    </div>
                    <p className="flex items-center justify-between">
                      <span className="font-semibold">Sınıfı:</span>
                      <span>{item.sinif}</span>
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="px-4 py-2 bg-green-300 text-gray-700 rounded shadow-lg font-semibold"
                      onClick={() =>
                        alert("Kursiyer durumu pasif olarak değiştirilmiştir.")
                      }
                    >
                      Pasif
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        </tbody>
        <tfoot className="flex flex-col justify-center gap-2 mt-2 w-full">
          <div className="bg-red-200 text-gray-700 p-2">Süresi Bitenler</div>
          <div className="bg-yellow-200 text-gray-700 p-2">
            Süresi 10 gün ve daha az kalanlar
          </div>
          <div className="bg-green-200 text-gray-700 p-2">
            Süresi 10 günden fazla olanlar
          </div>
        </tfoot>
      </table>
    </div>
  );
};

export default ExpiredTable;
