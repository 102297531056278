import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../firebase";
import { login as loginHandle } from "../store/auth";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import MenuCard from "../components/MenuCard";

import { links } from "../data/data";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";

const date = new Date();
let hour = date.getHours();

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await login(email, password);
    dispatch(loginHandle(user));
    navigate("/", {
      replace: true,
    });
  };

  const { user } = useSelector((state) => state.auth);
  if (user) {
    return (
      <div>
        <Navbar />
        <Title
          title={
            hour < 6
              ? "İyi geceler 🌛"
              : hour < 12
              ? "Günaydın 🌝"
              : hour < 18
              ? "İyi günler 🌞"
              : "İyi akşamlar 🌚"
          }
        />
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-4 place-items-center">
          {links.map((link, index) => (
            <MenuCard
              key={index}
              path={link.path}
              icon={link.icon}
              title={link.name}
              color1={link.color1}
              color2={link.color2}
            />
          ))}
        </div>
      </div>
    );
  }

  // return (
  //   <div>
  //     <Link
  //       to="/"
  //       className="flex items-center justify-center w-screen h-screen text-2xl font-semibold text-blue-500 underline"
  //     >
  //       Giris Yap
  //     </Link>
  //   </div>
  // );

  return (
    <div className="w-screen h-screen flex flex-col items-center gap-4 px-4 pt-20 mx-auto md:h-screen bg-[#2560d4]">
      <div className="bg-white p-1 rounded-full">
        <a href="/" className="">
          <img
            src="https://wakeswimacademy.com/yeni/tema/logo.png"
            alt="logo"
            className="w-24 h-24 object-cover"
          />
        </a>
      </div>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Yönetici Hesabı
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Email Adresi
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="name@mail.com"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Parola
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    required=""
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="remember" className="text-gray-500">
                    Beni Hatırla!
                  </label>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center disabled:opacity-40"
              disabled={!email || !password}
            >
              Giriş Yap
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
