import React from "react";

const Title = ({ title }) => {
  return (
    <h1 className="text-3xl font-bold leading-7 text-gray-900 text-center my-8">
      {title}
    </h1>
  );
};

export default Title;
